//
// Component: Grid
//
// ========================================================================

// Variables
// ========================================================================

// Used in grid, column, container, align, card, padding
$global-gutter: 2em !default;
$global-medium-gutter: 2em !default;
$global-large-gutter: 4em !default;

// Miscellaneous
// ========================================================================

@mixin hook-grid-misc() {
  .uk-grid-products {
    margin-top: 55px;
    margin-left: -30px;
    > * {
      padding-left: 30px;
    }
  }

  .uk-grid-wishlist {
    margin-top: 50px;
    margin-left: -20px;

    > * {
      padding-left: 20px;
    }
  }

  .uk-grid-booking {
    margin-top: 60px;
    margin-left: -103px;

    > * {
      padding-left: 103px;
    }
  }

  .uk-grid-rate {
    margin-left: -21px;
    margin-top: 25px;

    > * {
      padding-left: 21px;

      &:nth-child(1) {
        max-width: 142px;

        img {
          border-radius: 14.0637px;
        }
      }

      &:nth-child(2) {
        > *:nth-child(n + 2) {
          margin-top: 4px;
        }
      }
    }
  }

  .uk-grid-complaint {
    margin-left: 0;

    > * {
      padding-left: 0;
    }
  }

  .uk-grid-otp {
    margin-left: -25px;

    > * {
      padding-left: 25px;

      input {
        text-align: center;
        padding: 30px 20px;
        font-size: 30px;
        background-color: #f6f6f6;
        border-radius: 6px;
        border: 2.30588px solid transparent;
        outline: none;

        &:focus {
          background-color: transparent;
          border-color: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  #otp-verify .uk-grid-otp {
    input {
      padding: 14px 8px;
      font-size: 24px;
    }
  }

  .uk-grid-chat,
  .uk-grid-reviews {
    > *:nth-child(1) {
      width: 50px;
      height: 50px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    @media (min-width: $breakpoint-medium) {
      > *:nth-child(1) {
        width: 70px;
        height: 70px;
      }
    }
  }

  .uk-grid-guides {
    margin-top: 20px;
    @media (min-width: $breakpoint-medium) {
      margin-top: 45px;
    }
  }

  .uk-grid-notifications {
    margin-top: 20px;
    @media (min-width: $breakpoint-medium) {
      margin-top: 105px;
    }
  }

  .uk-grid-calendar {
    margin-top: 30px;
    @media (min-width: $breakpoint-medium) {
      margin-top: 60px;
    }

    > * {
      img {
        width: 100%;
        object-fit: cover;
        object-position: top;
        border-radius: 10px;
        @media (min-width: $breakpoint-medium) {
          height: 130px;
        }
      }

      .uk-button {
        padding: 10px;
        font-size: 14px;
      }
    }
  }
}
