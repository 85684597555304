//
// Component: Icon
//
// ========================================================================

// Variables
// ========================================================================

// Style modifiers
// ========================================================================

//
// Link
//

// @mixin hook-icon-link(){}

// @mixin hook-icon-link-hover(){}

// @mixin hook-icon-link-active(){}

//
// Button
//

// @mixin hook-icon-button-hover(){}

// @mixin hook-icon-button-active(){}

// Miscellaneous
// ========================================================================

@mixin hook-icon-misc() {
	.uk-icon-black svg path {
		fill: #000;
	}
	.uk-icon-white svg path {
		fill: #fff;
		stroke: white;
	}
	.uk-icon-lightgray svg path {
		fill: rgba($color: #000000, $alpha: 0.1);
	}
	.uk-icon-navbar {
		width: 30px;
		height: 30px;

		* {
			width: 100%;
			height: 100%;
		}
	}

	.uk-icon-guide {
		width: 35px;
		height: 35px;
		@media (min-width: $breakpoint-medium) {
			width: 38px;
			height: 33px;
		}
	}

	.uk-icon-guide-item path {
		fill: white;
	}

	.uk-icon-product-added {
		width: 100px;
		height: 100px;
		background-color: $global-green-color;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.uk-icon-left {
		width: 40px;
		height: 40px;

		svg {
			width: 100%;
			height: 100%;
		}

		&-small {
			width: 23px;
			height: 23px;

			svg {
				width: 100%;
				height: 100%;
			}
		}
	}

	.uk-icon-edit {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 35px;
		height: 35px;
		background-color: #fff;
		border-radius: 50%;
		text-align: center;
		line-height: 35px;
	}

	.uk-cb-inactive path {
		fill: rgba(#000000, 0.5);
	}

	.uk-icon-info {
		width: 56px;
		height: 56px;

		svg {
			width: 100%;
			height: 100%;
		}
	}

	.uk-icon-info-light {
		width: 21px;
		height: 21px;

		svg {
			width: 100%;
			height: 100%;
		}
	}

	.uk-icon-like-btn {
		padding: 0 10px;
		// padding-inline: 0;
		// padding-block: 0;
		height: 30px;
		background-color: #fff;
		border-width: 0;
		border-radius: 10%;
		border: none;
		display: flex;
		justify-content: center;
		gap: 5px;
		align-items: center;
		box-shadow: 0px 5.14627px 32.1642px rgba(0, 0, 0, 0.15);
		cursor: pointer;
		position: absolute;
		bottom: 14px;
		left: 14px;
		font-size: 0.8rem;

		svg {
			width: 12px;
			height: 12px;
		}

		&.active {
			background-color: $global-green-color;

			svg path {
				fill: $global-inverse-color;
			}
		}
	}

	.uk-icon-heart {
		width: 42px;
		height: 42px;
		display: flex;
		justify-content: center;
		align-items: center;
		// box-shadow: 0px 5.14627px 32.1642px rgba(0, 0, 0, 0.15);
		cursor: pointer;
		
		position: absolute;
		top: 14px;
		right: 14px;

		svg {
			width: 25px;
			height: 23px;
		}
	
		
		&.inactive svg path {
				width: 2px;
			    height: 2px;
				fill:none;
				stroke: #000;
			}
		
		&.active svg path {
				width: 2px;
			    height: 2px;
				fill:red;
				stroke: red;
			}
		
	}

	.uk-icon-wallet {
		width: 38px;
		height: 31px;

		* {
			width: 100%;
			height: 100%;
		}
	}

	.uk-icon-tshirt {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		background-color: #dbf9e8;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.uk-icon-filter {
		width: 71px;
		height: 71px;
		border-radius: 50%;
		padding: 21px;
		box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
		box-sizing: border-box;
	}

	.uk-icon-star.selected path {
		fill: $global-green-color;
	}
}

// Inverse
// ========================================================================

// @mixin hook-inverse-icon-link(){}
// @mixin hook-inverse-icon-link-hover(){}
// @mixin hook-inverse-icon-link-active(){}

// @mixin hook-inverse-icon-button(){}
// @mixin hook-inverse-icon-button-hover(){}
// @mixin hook-inverse-icon-button-active(){}
