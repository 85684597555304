//
// Component: Section
//
// ========================================================================

// Variables
// ========================================================================

// Component
// ========================================================================

// @mixin hook-section(){}

// Style modifiers
// ========================================================================

// @mixin hook-section-default(){}

// @mixin hook-section-muted(){}

// @mixin hook-section-primary(){}

// @mixin hook-section-secondary(){}

// Miscellaneous
// ========================================================================

@mixin hook-section-misc {
	.uk-section-signin {
		min-height: 100vh;

		.uk-button {
			max-width: 455px;
			width: 100%;
		}

		.uk-icon-left {
			width: 24px;
			height: 24px;
		}

		.uk-icon-info {
			width: 22px;
			height: 22px;
		}
	}

	.uk-section-reset {
		.uk-button {
			background-color: #bfbfbf;
			border-color: transparent;
			color: white;
		}
	}

	.uk-section-products {
		padding: 55px 0;
	}

	.uk-section-product {
		.uk-slider-items img {
			height: 100%;
			object-fit: cover;
			object-position: top;
		}

		.uk-button {
			width: 100%;
			padding: 12px;
		}

		.uk-icon-heart {
			position: relative;
			top: unset;
			right: unset;
		}

		.uk-button-success {
			padding: 18px;
		}

		@media (min-width: $breakpoint-medium) {
			.uk-button {
				max-width: 200px;
			}
		}
	}

	.uk-section-wallet {
		.uk-container-wallet > div {
			@media (min-width: $breakpoint-medium) {
				padding: 40px 66px;
			}
		}
	}

	.uk-section-booking {
		.uk-card-cart-item {
			.uk-card-body {
				gap: 24px;
				padding: 24px 32px;
			}

			img {
				max-width: 210px !important;
				height: 214px !important;
			}

			.uk-button-primary {
				padding: 18px 38px !important;
				height: unset !important;
			}
		}

		hr {
			opacity: 0.1;
			border-top: 1.35983px solid #000000;
			margin-top: 30px;
		}

		input[type="radio"] {
			width: 30px;
			height: 30px;
			accent-color: green;
		}

		.uk-button-success {
			margin-top: 20px;
		}

		&.fixed {
			position: fixed;
			width: 100%;
			margin-top: 0px !important;
			bottom: 0;
			box-shadow: 0px 5.62051px 35.1282px rgba(0, 0, 0, 0.1);
			background-color: $global-inverse-color;

			.uk-button-primary {
				padding: 18px 70px;
			}
		}

		&:not(.fixed) {
			padding-bottom: 200px;
		}
	}

	.uk-section-feedback {
		.uk-text-6-big {
			margin-top: 60px;
		}

		.uk-text-6 {
			margin-top: 30px;

			+ .uk-text-6-big {
				margin-top: 80px;
			}
		}

		.uk-list-feedback {
			margin-top: 22px;
		}

		.uk-textarea {
			padding: 32px 29px;
			border: 1.59876px solid rgba(0, 0, 0, 0.25);
			border-radius: 7.99381px;
			background-color: transparent;
		}
	}

	.uk-section-profile {
		.uk-button-primary {
			margin-top: 57px;
		}

		.uk-list-border {
			margin-top: 38px;
		}
	}

	.uk-section-complaint {
		min-height: 100vh;

		.uk-card-complaint {
			margin-top: 30px;
		}

		.uk-icon-left {
			width: 18px;
			height: 18px;
		}

		.uk-textarea {
			border: 1.16279px solid rgba(0, 0, 0, 0.25);
			border-radius: 2.32558px;
			background-color: rgba(217, 217, 217, 0.15);
			font-size: 16px;
			padding: 13px 20px;
			margin-top: 26px;
		}

		.uk-text-8-big.uk-text-muted {
			margin-top: 42px;
		}

		input[type="radio"] {
			width: 23px;
			height: 23px;
			accent-color: green;
		}

		.uk-list {
			margin-top: 25px;
		}

		.uk-list > li {
			display: flex;
			align-items: center;

			&:nth-child(n + 2) {
				margin-top: 18px;
			}

			input {
				margin: 0;
			}
			label {
				margin-left: 15px;
			}
		}
	}

	.uk-section-review {
		.uk-flex {
			gap: 28px;

			img {
				width: 178px;
				height: 186px;
				object-fit: cover;
				border-radius: 18px;
				object-position: top;
			}
		}

		.uk-text-light {
			line-height: 41px;
		}

		.uk-button {
			padding: 16px 34px;
		}

		.uk-textarea {
			padding: 28px 35px;
			margin-top: 18px;
			border-radius: 3.51354px;
		}
	}

	.uk-section-filters {
		padding-top: 1em;
		padding-bottom: 1em;
		margin-bottom: 1rem;
		button {
			width: max-content;
			font-size: 0.8rem;
			box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);

			&.active {
				background-color: $global-green-color;
				color: $global-inverse-color !important;
			}
		}
	}

	.uk-section-filters + .uk-section-products {
		padding-top: 0;
	}

	.uk-section-booking-details {
		padding: 1em;
		@media (min-width: $breakpoint-medium) {
			padding: 36px 50px 36px 22px;
		}
		box-sizing: border-box;
		position: absolute;
		right: 0;
		top: 0;
		max-width: 530px;
		width: 100%;
		height: 100%;
		background-color: white;
		filter: drop-shadow(-10px 4px 25px rgba(0, 0, 0, 0.15));
		overflow-y: auto;

		.uk-icon-lightgray {
			max-width: 40px;
		}

		.uk-booking-details-content {
			max-width: 385px;
			width: 100%;
			.uk-product-image {
				max-width: 146px;
				width: 100%;
				border-radius: 13px;
			}
		}
	}

	.uk-section-payments {
		padding-bottom: 250px;
	}

	.uk-section-inbox {
		.uk-grid-inbox > *:nth-child(1) {
			padding-top: 2em;
			padding-bottom: 1em;
		}
		@media (min-width: $breakpoint-medium) {
			.uk-grid-inbox > * {
				padding-top: 2em;
				padding-bottom: 0;
			}
			.uk-grid-inbox > *:nth-child(2) {
				border-left: 1px solid $global-primary-background;
			}

			height: 100%;

			&.chat-screen {
				min-height: 500px;
				height: calc(100vh - 130px);
				overflow: hidden;
			}
		}
	}

	.uk-section-otp {
		padding: 0 20px;
		height: 50px;
		button {
			padding: 0 20px;
		}
	}

	.uk-section-message {
		position: sticky;
		width: 100%;
		box-sizing: border-box;
		bottom: 0;
		box-shadow: 0 -2px 5px rgba(#000000, 0.1);
	}
}
