// Variables
// ========================================================================

/* ========================================================================
   Component: Card
 ========================================================================== */

// Hooks
// ========================================================================

// @mixin hook-card(){}
// @mixin hook-card-body(){}
// @mixin hook-card-header(){}
// @mixin hook-card-footer(){}
// @mixin hook-card-media(){}
// @mixin hook-card-media-top(){}
// @mixin hook-card-media-bottom(){}
// @mixin hook-card-media-left(){}
// @mixin hook-card-media-right(){}
// @mixin hook-card-title(){}
// @mixin hook-card-badge(){}
// @mixin hook-card-hover(){}
// @mixin hook-card-default(){}
// @mixin hook-card-default-title(){}
// @mixin hook-card-default-hover(){}
// @mixin hook-card-default-header(){}
// @mixin hook-card-default-footer(){}
// @mixin hook-card-primary(){}
// @mixin hook-card-primary-title(){}
// @mixin hook-card-primary-hover(){}
// @mixin hook-card-secondary(){}
// @mixin hook-card-secondary-title(){}
// @mixin hook-card-secondary-hover(){}

@mixin hook-card-misc() {
	.uk-card-product-item {
		position: relative;
		img {
			background-image: linear-gradient(180deg, #f1ece8 0%, #ede3d9 100%);
			border-radius: 10px;
			box-shadow: 0px 5.14627px 32.1642px rgba(0, 0, 0, 0.05);
			max-height: 220px;
			object-fit: contain;
		}

		a {
			color: $global-dark-color;
		}

		.uk-card-body {
			padding: 0;
			padding-top: 18px;
			font-size: 14px;
		}

		.uk-card-title {
			font-size: 18px;
			line-height: 23px;
			font-weight: 600;
		}
	}

	.uk-card-store-details {
		margin-top: 65px;
		background-color: rgba(#d9d9d9, 0.2);
		border-radius: 10px;

		.uk-card-body {
			padding: 30px 44px;

			.uk-grid {
				margin-left: -36px;

				> * {
					padding-left: 36px;
				}
			}

			img {
				width: 106px;
				height: 106px;
				border-radius: 50%;
				object-fit: cover;
			}
		}
	}

	.uk-card-watchdemo {
		position: relative;
		background-color: #fff5bf;
		border-radius: 4px;

		.uk-card-body {
			padding: 1.5em;
			max-width: 428px;
			margin-left: auto;
		}

		@media (min-width: $breakpoint-medium) {
			.uk-card-body {
				padding: 40px 30px;
			}

			&::before {
				content: "";
				width: 250px;
				height: 210px;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				background-image: url("../../../public/images/pinch.png");
				background-position: center;
				background-size: 100%;
				background-repeat: no-repeat;
			}
		}
	}

	.uk-card-invite {
		margin-top: 40px;
		border-radius: 4px;

		.uk-card-body {
			padding: 1em;
		}

		@media (min-width: $breakpoint-medium) {
			.uk-card-body {
				padding: 25px 30px;
			}
		}
	}

	.uk-card-cart-item {
		border: 1.3px solid rgba(0, 0, 0, 0.25);
		border-radius: 13px;
		box-shadow: 0px 5.17243px 32.3277px rgba(0, 0, 0, 0.05);

		.uk-card-body {
			gap: 16px;
			padding: 18px 22px;

			img {
				@media (min-width: $breakpoint-small) {
					max-height: 200px;
				}
				width: 100%;
				border-radius: 13px;
				object-fit: cover;
				object-position: top;
			}

			.uk-button {
				padding: 11px;
			}

			.uk-button-primary {
				height: 50px;
				width: 100%;
			}

			.uk-button-danger {
				width: 50px;
				height: 50px;

				svg {
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	.uk-card-booking-item {
		border: 1.19527px solid rgba(0, 0, 0, 0.25);
		border-radius: 11.9527px;
		box-shadow: 0px 4.78106px 29.8817px rgba(0, 0, 0, 0.1);

		.uk-card-body {
			padding: 15px 20px;

			.uk-flex {
				gap: 16px;
			}

			.uk-grid {
				margin-left: -16px;

				> * {
					padding-left: 16px;
				}
			}

			img {
				width: 100%;
				object-fit: cover;
				border-radius: 12px;

				@media (min-width: $breakpoint-medium) {
					width: 132px;
					height: 138px;
				}
			}

			.uk-button-danger {
				color: #f03736;

				&:hover {
					color: $global-inverse-color;
				}
			}
		}
	}

	.uk-card-booked {
		max-width: 421px;
		.uk-card-body {
			padding: 45px 33px 36px 33px;
		}
	}

	.uk-card-complaint {
		border: 1.16279px solid rgba(0, 0, 0, 0.25);
		box-shadow: 0px 4.65116px 29.0698px rgba(0, 0, 0, 0.1);
		border-radius: 11.6279px;

		.uk-card-body {
			padding: 16px 19px;
			gap: 16px;

			img {
				width: 129px;
				height: 134px;
				object-fit: cover;
				border-radius: 11.6279px;
			}

			.uk-button {
				width: 100%;
				padding: 12px 65px;
				font-size: 12px;
			}
		}
	}

	.uk-card-deactivate {
		margin-top: 35px;
		border: 1.33926px solid rgba(0, 0, 0, 0.25);
		border-radius: 6.6963px;
		.uk-card-body {
			padding: 14px;
		}
	}

	.uk-card-review {
		border: 1.75677px solid rgba(#000000, 0.1);
		text-align: center;
		border-radius: 8.78386px;

		.uk-card-body {
			padding: 15px 40px;

			.uk-flex {
				gap: 21px;
			}
		}
	}

	.uk-card-sort {
		max-width: 429px;
		width: 100%;
		border-radius: 6.40725px;
		box-shadow: 0px 5.1258px 32.0362px rgba(0, 0, 0, 0.15);
		z-index: 1;
		transform: translate(-100%, -50%);

		.uk-card-header {
			border-bottom: 1.28145px solid rgba(#000000, 0.2);
		}

		.uk-button {
			padding: 18px;
			margin-top: 110px;
		}

		@media (max-width: $breakpoint-small) {
			left: 0;
			top: 105px;
			transform: unset;
			max-width: 100%;
		}
	}

	.uk-card-search {
		max-width: 429px;
		width: 100%;
		border-radius: 6.40725px;
		box-shadow: 0px 5.1258px 32.0362px rgba(0, 0, 0, 0.15);
		z-index: 1;
		transform: translate(-100%, -50%);

		.uk-button {
			padding: 18px;
			margin-top: 110px;
		}

		@media (max-width: $breakpoint-small) {
			left: 0;
			top: 105px;
			transform: unset;
			max-width: 100%;
		}
	}

	.uk-card-profile {
		max-width: 471px;
		width: 100%;
		border-radius: 6.40725px;
		box-shadow: 0px 5.1258px 32.0362px rgba(0, 0, 0, 0.15);
		z-index: 1;
		right: 0;
		top: 100%;

		.uk-card-body {
			padding: 50px 60px;
		}

		.uk-profile-card {
			background-color: opacify(#d8d8d8, 0.4);
			padding: 10px;

			img {
				width: 60px;
				height: 60px;
				object-fit: cover;
				border-radius: 50%;
			}
		}

		.uk-button {
			padding: 18px;
		}

		.uk-button-black-bg {
			font-size: 12px;
			padding: 15px;
			line-height: 1;
		}
	}

	.uk-card-payable {
		border: 2px solid rgba(#000000, 0.1);
		border-radius: 10px;
	}

	.uk-card-guide-item {
		.uk-card-body {
			padding: 0;
		}
	}

	.uk-card-notification-item {
		.uk-card-body {
			padding: 0;

			img {
				max-width: 90px;
				width: 100%;
				aspect-ratio: 1/1;
				border-radius: 50%;
				object-fit: cover;
				object-position: top;
			}

			button {
				padding: 10px;
			}
		}

		.uk-background-wash {
			border-radius: 6px;
		}
	}

	.uk-card-user-review {
		padding-right: 2em;
		max-width: 500px;
		margin: auto;
		.uk-card-body {
			box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.25);
			border-radius: 5px;
			padding: 18px 30px;

			img {
				border-radius: 50%;
			}
		}
	}
}

// Inverse
// ========================================================================
