// -----------------------------------------------------------------------------
// Colours
// -----------------------------------------------------------------------------

$global-color: #212931;
$global-emphasis-color: #f4fff9;
$global-muted-color: rgba(#000000, 0.5);
$global-dark-color: #05041e;
$global-green-color: #46766e;

$global-primary-color: #0c0b2d;
$global-secondary-color: #f4fff9;
$global-tertiary-color: #fc1aff;
$global-quaternary-color: #494bff;

$global-link-color: $global-green-color;
$global-link-hover-color: darken($global-link-color, 10%);

$base-heading-color: inherit !default;
$base-em-color: $global-color;

$global-inverse-color: #fff;

$template_colours: (
  "white": #fff,
  primary: $global-primary-color,
  secondary: $global-secondary-color,
  tertiary: $global-tertiary-color,
  quaternary: $global-quaternary-color,
  "green": $global-green-color,
  "red": #f03736,
  "lightblue": #b4cbd7,
);

// -----------------------------------------------------------------------------
// Backgrounds
// -----------------------------------------------------------------------------

$global-background: #fff;

$global-dark-background: $global-dark-color;
$global-muted-background: #f6f6f6;
$global-primary-background: $global-primary-color;
$global-secondary-background: $global-secondary-color;

// -----------------------------------------------------------------------------
// Borders
// -----------------------------------------------------------------------------

$global-border: $global-muted-color;
$global-border-width: 1px;

// -----------------------------------------------------------------------------
// Alerts
// -----------------------------------------------------------------------------

$global-success-background: #61b832;
$global-warning-background: #e47742;
$global-danger-background: #de4343;
$global-info-background: #4c98e5;

// -----------------------------------------------------------------------------
// Social Palette
// -----------------------------------------------------------------------------

$social_colours: (
  facebook: #3b5998,
  twitter: #55acee,
  linkedin: #0077b5,
  google: #dc4e41,
  youtube: #cd201f,
  pinterest: #bd081c,
  tumblr: #35465c,
  instagram: #3f729b,
  rss: #f26522,
);
