// Name:            Slider
// Description:     Component to create horizontal sliders
//
// Component:       `uk-slider`
//
// Sub-objects:     `uk-slider-container`
//                  `uk-slider-items`
//
// States:          `uk-active`
//
// ========================================================================

// Variables
// ========================================================================

// Hooks
// ========================================================================

// @mixin hook-slider(){}
@mixin hook-slider-misc() {
	.uk-slider-logos {
		> li {
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				max-width: 150px;
				max-height: 150px;
				object-fit: contain;
			}
		}
	}

	.uk-slider-product-images {
		img {
			width: 100%;
			height: 100px;
			object-fit: cover;
		}

		.uk-close {
			background-color: white;
			border-radius: 50%;
			width: 20px;
			height: 20px;
			display: block;
		}
	}

	.uk-slider-size-picker {
		input[type="radio"] {
			display: none;
		}
		
		input[type="radio"]:checked + label img {
			border-color: $global-green-color;
		}
		
		img {
			width: 100%;
			height: 150px;
			object-fit: contain;
			object-position: top;
			border: 2px solid transparent;
		}
	}
}
