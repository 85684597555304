// Variables
// ========================================================================

$dotnav-margin-horizontal: 24px !default;
$dotnav-margin-vertical: $dotnav-margin-horizontal !default;

$dotnav-item-width: 12px !default;
$dotnav-item-height: $dotnav-item-width !default;
$dotnav-item-border-radius: 50% !default;

$dotnav-item-background: #fff !default;
$dotnav-item-hover-background: $global-primary-background !default;
$dotnav-item-onclick-background: $global-primary-background !default;
$dotnav-item-active-background: $global-primary-background !default;

// @mixin hook-dotnav(){}
@mixin hook-dotnav-item() {
	border: 1px solid $global-primary-background;
}

// @mixin hook-dotnav(){}
// @mixin hook-dotnav-item(){}
// @mixin hook-dotnav-item-hover(){}
// @mixin hook-dotnav-item-onclick(){}
// @mixin hook-dotnav-item-active(){}
@mixin hook-dotnav-misc() {
	.uk-dotnav-horizontal {
		> * > * {
			display: block;
			width: 40px;
			height: 10px;
			border-radius: 4px;
		}
	}
}
