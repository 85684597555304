// Variables
// ========================================================================

$form-height: 62px !default;

// Hooks
// ========================================================================

// @mixin hook-form(){}
@mixin hook-form-single-line() {
	border-radius: 7px;
}
// @mixin hook-form-multi-line(){}
// @mixin hook-form-focus(){}
// @mixin hook-form-disabled(){}
// @mixin hook-form-danger(){}
// @mixin hook-form-success(){}
// @mixin hook-form-blank(){}
// @mixin hook-form-blank-focus(){}
// @mixin hook-form-radio(){}
// @mixin hook-form-radio-focus(){}
// @mixin hook-form-radio-checked(){}
// @mixin hook-form-radio-checked-focus(){}
// @mixin hook-form-radio-disabled(){}
// @mixin hook-form-legend(){}
@mixin hook-form-label() {
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;

	& + .uk-form-controls {
		margin-top: 8px;
	}
}
// @mixin hook-form-stacked-label(){}
// @mixin hook-form-horizontal-label(){}
// @mixin hook-form-icon(){}
@mixin hook-form-misc() {
	.uk-select {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='12' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.41 0 6 4.327 10.59 0 12 1.332 6 7 0 1.332 1.41 0Z' fill='%23B8B8B8'/%3E%3C/svg%3E") !important;
		background-position: 95% !important;
		border-color: #ccc;
		position: relative;
		background-color: transparent;
	}
	.uk-form-signup {
		max-width: 403px;
		margin: auto;
	}

	.input-range {
		width: calc(100% - 20px);
		margin: auto;
	}

	.input-range__track--active {
		background-color: $global-green-color;
	}

	.input-range__slider {
		background-color: $global-green-color;
		border-color: $global-green-color;
	}

	.input-range__label--min,
	.input-range__label--max {
		.input-range__label-container {
			display: none;
		}
	}

	.uk-input-wrapper {
		display: flex;
		align-items: center;
		input[type="text"],
		input[type="number"],
		input[type="email"],
		input[type="tel"] {
			border: 1.77201px solid rgba(0, 0, 0, 0.25);
			border-radius: 9px;
			background-color: transparent;
			padding: 18px;
			height: unset;
		}

		input[type="checkbox"] {
			width: 25px;
			height: 25px;
			margin: 0;
			accent-color: #000;
		}
	}

	.uk-input-search {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $global-muted-background;
		padding: 7px 50px;
		border-radius: 73px;
		width: 200px;
		box-sizing: border-box;

		input {
			background-color: unset;
			height: unset;

			&:focus {
				background-color: unset;
			}
		}
	}
}
