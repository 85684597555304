//
// Component: Base
//
// ========================================================================

// Variables
// ========================================================================

$global-border: rgba(#000000, 0.05);
$base-margin-vertical: 0;

:focus-visible {
	outline: none !important;
}

::selection {
	-webkit-text-fill-color: #fff !important;
}

// Body
// ========================================================================

// @mixin hook-base-body(){}

// Links
// ========================================================================

@mixin hook-base-link() {
	transition: color 0.2s;
}

@mixin hook-base-link-hover() {
	text-decoration: none;
}

// Text-level semantics
// ========================================================================

// Headings
// ========================================================================

// @mixin hook-base-heading(){}

// @mixin hook-base-h1(){}

// @mixin hook-base-h2(){}

// @mixin hook-base-h3(){}

// @mixin hook-base-h4(){}

// @mixin hook-base-h5(){}

// @mixin hook-base-h6(){}

// Horizontal rules
// ========================================================================

// @mixin hook-base-hr(){}

// Blockquotes
// ========================================================================

// Preformatted text
// ========================================================================

// Miscellaneous
// ========================================================================

@mixin hook-base-misc {
	html {
		// height: 100%; Covered by UIKit
		overflow-x: hidden;
		overflow-y: auto;
		font-size: 16px;
		color: $global-color;
		-webkit-font-smoothing: antialiased;
	}

	body {
		min-height: 100%;
		overflow-x: hidden;
	}

	@if (mixin-exists(hook-base-wysiwyg)) {
		@include hook-base-wysiwyg();
	}
}

// Inverse
// ========================================================================

// @mixin hook-inverse-base-link(){}
// @mixin hook-inverse-base-link-hover(){}

// @mixin hook-inverse-base-heading(){}

// @mixin hook-inverse-base-h1(){}
// @mixin hook-inverse-base-h2(){}
// @mixin hook-inverse-base-h3(){}
// @mixin hook-inverse-base-h4(){}
// @mixin hook-inverse-base-h5(){}
// @mixin hook-inverse-base-h6(){}

// @mixin hook-inverse-base-hr(){}
