//
// Component: WYSIWYG
//
// ========================================================================

// Variables
// ========================================================================

@mixin hook-base-wysiwyg {
  .uk-wysiwyg-default {
    ol ul {
      padding-left: 1em;
    }
    p {
      text-align: justify;
    }
    p,
    ul,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    ul {
      margin-bottom: 2em;
    }

    img {
      width: 100%;
    }

    p + p {
      margin-top: 0;
    }

    .alignleft {
      float: left;
      margin: 0 15px 15px 0;
    }

    .alignright {
      float: right;
      margin: 0 0 15px 15px;
    }

    blockquote {
      @extend .uk-text-5;

      background: $global-muted-background;
      padding: 30px;
      margin: 15px 0;
    }

    figure {
      max-width: 100%;

      figcaption {
        text-align: center;
        margin-top: 6px;
        font-style: italic;
      }
    }
  }
}
