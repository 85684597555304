//
// Component: Inverse
//
// ========================================================================

// Variables
// ========================================================================

// Component
// ========================================================================

@mixin hook-inverse {
	color: $global-inverse-color;
	a,
	.uk-navbar-nav > li > a {
		color: $global-inverse-color;
	}
	.uk-navbar-toggle {
		fill: white !important;
	}
	// 	h1,h2,h3,h4,h5,h6,a {
	// 		color: $global-inverse-color !important;
	// 	}
	// 	a:hover {
	// 		color: lighten($global-primary-color, 15) !important;
	// 	}
}
