//
// Component: Button
//
// ========================================================================

// Variables
// ========================================================================

$button-font-size: 18px !default;
$button-line-height: 1.5 !default;

$button-disabled-background: rgba($global-green-color, 0.5) !default;
$button-disabled-color: $global-inverse-color !default;

// Component
// ========================================================================

@mixin hook-button-misc() {
  .uk-button {
    padding: 18px;
    font-weight: 600;
    text-align: center;
    border-radius: 7px;
    border: 2px solid transparent;
    transition: 0.3s ease;
  }

  .uk-button-white {
    color: black !important;
    background-color: $global-inverse-color;
    border-color: rgba(#1e1d12, 0.25);

    &:hover {
      background-color: $global-secondary-background;
      color: $global-dark-color !important;
    }
  }

  .uk-button-rounded {
    border-color: transparent;
    box-shadow: 0px 5.98876px 37.4298px rgba(0, 0, 0, 0.1);
    border-radius: 74.8595px;
    padding: 10px 36px;
  }

  .uk-button-black {
    color: black;
    background-color: transparent;
    border-color: black;
    font-size: 20px;
    line-height: 47px;
    padding: 8px 40px;

    &:hover {
      background-color: black;
      color: $global-inverse-color;

      svg path {
        fill: white;
      }
    }
  }

  .uk-button-black-bg {
    color: white;
    background-color: black;
    border-color: black;
    font-size: 20px;
    line-height: 47px;
    padding: 8px 40px;

    &:hover {
      background-color: white;
      color: black;

      svg path {
        stroke: $global-primary-background;
      }
    }
  }

  .uk-button-lightgray {
    background-color: #d9d9d9;
    color: $global-primary-background;
    border: 2px solid #d9d9d9;

    &:hover {
      background-color: transparent;
      color: $global-primary-background;
    }
  }

  .uk-button-link {
    padding-left: 0;
    padding-right: 0;
    color: $global-green-color;
    display: inline-block;
    max-width: fit-content;
  }

  .uk-button-noeffect {
    background-color: transparent;
    border: unset;
    outline: unset;
    cursor: pointer;
  }

  .uk-button-success {
    background-color: rgba(#2ab365, 0.05);
    border-radius: 4px;
    color: $global-green-color;
    padding: 16px 50px;
    display: flex;
    width: fit-content;
    align-items: center;
  }

  .uk-button-error {
    background-color: #fef2f2;
    border-radius: 6.51644px;
    padding: 18px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    color: #f50100;

    &:hover {
      color: darken(#f50100, 5);
    }
  }

  .uk-button-switcher {
    border-radius: 66px;
    box-shadow: 0px 0px 33.0205px rgba(0, 0, 0, 0.1);
    padding: 12px 26px;
    font-size: 15px;
  }

  .uk-button-wrapper > *:nth-child(n + 2) {
    margin-left: 21px;
  }

  .uk-button-pill {
    display: inline-block;
    max-width: 190px;
    width: 100%;
    box-sizing: border-box;
    padding: 2px 8px;
    border: 0.793496px solid #000000;
    border-radius: 2.38049px;
  }
}

// @mixin hook-button(){}
// @mixin hook-button-hover(){}
// @mixin hook-button-active(){}
// @mixin hook-button-default(){}
// @mixin hook-button-default-hover(){}
// @mixin hook-button-default-active(){}
@mixin hook-button-primary() {
  background-color: $global-green-color;

  &:disabled {
    padding: 16px 34px;
    font-size: 18px;
  }
}
@mixin hook-button-primary-hover() {
}
// @mixin hook-button-primary-active(){}
@mixin hook-button-secondary() {
  background-color: $global-inverse-color;
  color: #6a6a6a;
}
@mixin hook-button-secondary-hover() {
  background-color: $global-color;
  color: $global-inverse-color;
}
// @mixin hook-button-secondary-active(){}
@mixin hook-button-danger() {
  color: #f03736;
  background-color: white;
  border: 2px solid #f03736 !important;
}
@mixin hook-button-danger-hover() {
  svg path {
    fill: white;
  }
}
// @mixin hook-button-danger-active(){}
// @mixin hook-button-disabled(){}
@mixin hook-button-small() {
  padding: 8px 18px !important;
}
// @mixin hook-button-large(){}
// @mixin hook-button-text(){}
// @mixin hook-button-text-hover(){}
// @mixin hook-button-text-disabled(){}
// @mixin hook-button-link(){}
// @mixin hook-button-misc(){}
