//
// Component: Container
//
// ========================================================================

$container-max-width: 88.5em;

$container-padding-horizontal: 18px;
$container-padding-horizontal-l: 2em;
$container-padding-horizontal-xl: 2em;

// Variables
// ========================================================================

// Miscellaneous
// ========================================================================

@mixin hook-container-misc() {
	.uk-section-filter-cnt {
		svg {
			width: 20px;
			height: 20px;
		}
	}

	.uk-container {
		box-sizing: border-box;
		width: 100%;
		padding-left: 7rem;
		padding-right: 7rem;

		@media (max-width: $breakpoint-small) {
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}

	.uk-container-signup-form {
		max-width: 467px;
	}

	.uk-container-wallet {
		max-width: 830px;
		padding: 0;
	}

	.uk-container-feedback {
		max-width: 600px;
	}

	.uk-container-complaint {
		max-width: 464px;
	}

	.uk-container-add {
		max-width: 315px;
	}
}
