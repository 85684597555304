//
// Component: Background
//
// ========================================================================

// Variables
// ========================================================================

// Miscellaneous
// ========================================================================

@mixin hook-background-misc() {
	// Default backgrounds

	.uk-background-wash {
		background-color: rgba(lightgray, $alpha: 0.2);
	}

	.uk-background-wash-guide-item {
		background-color: rgba(black, $alpha: 0.3);
	}

	@each $name, $colour in $template_colours {
		.uk-background-#{$name} {
			background-color: $colour !important;
		}
	}
}
