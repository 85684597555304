$offcanvas-bar-background: $global-inverse-color !default;
$offcanvas-bar-width: 100% !default;
$offcanvas-bar-width-s: 100% !default;

// @mixin hook-offcanvas-bar(){}
@mixin hook-offcanvas-close() {
  top: 18px;
  right: 30px;
}
// @mixin hook-offcanvas-overlay(){}
// @mixin hook-offcanvas-misc(){}
