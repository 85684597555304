$global-font-family: "Poppins", sans-serif;
$global-line-height: 1.5;

// -----------------------------------------------------------------------------
// Line Height
// -----------------------------------------------------------------------------

$line-height-base: $global-line-height; // body copy
$line-height-s: 1.25; // everything else (typically headings)
$line-height-l: 1.6;
$line-height-xl: 2;

// -----------------------------------------------------------------------------
// Headings
// -----------------------------------------------------------------------------

// If you need any more of these variables they are in the base partial

$base-heading-font-family: $global-font-family !default;
$base-heading-font-weight: 700 !default;
$base-heading-text-transform: none !default;
$base-heading-margin-top: 0;

// -----------------------------------------------------------------------------
// Blockquote
// -----------------------------------------------------------------------------
