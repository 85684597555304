$list-margin-top: $global-medium-margin;

@mixin hook-list-misc() {
  .uk-list-reviews {
    li:nth-child(n + 2) {
      margin-top: 40px;
      padding-top: 40px;
      padding-bottom: 40px;
      border-top: 2.00877px solid rgba(#000000, 0.1);
    }
    p + p {
      margin-top: 6px;
    }
    p span {
      display: inline-block;
      text-transform: capitalize;
    }
    p span:nth-child(n + 2) {
      position: relative;
      padding-left: 34px;
      &::before {
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        content: "";
        background-color: black;
        left: 14px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }

  .uk-list-booking-options {
    li {
      display: flex;
      align-items: flex-start;
      padding-top: 1em;

      > *:nth-child(n + 2) {
        margin-left: 24px;
      }
    }

    li + li {
      border-top: 1.77201px solid rgba(#000000, 0.1);
    }
  }

  .uk-list-feedback {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > li {
      margin-top: 0 !important;
      width: 72px;
      height: 78px;
      font-size: 29.1526px;
      border-radius: 7.28815px;
      border: 2.18645px solid rgba(0, 0, 0, 0.3);
      text-align: center;
      line-height: 78px;

      &.selected {
        background-color: rgba($global-green-color, 0.3);
      }
    }
  }

  .uk-list-stars {
    margin-left: 1.5em;
    > *:nth-child(n + 2) {
      margin-left: 16px;
    }
  }

  .uk-list-files li {
    .uk-close {
      margin-left: 20px;

      svg line {
        stroke: red !important;
      }
    }
  }

  .uk-list-border {
    > * {
      border-bottom: 1.31884px solid rgba(#000000, 0.05);
      padding: 18px 0;

      .uk-icon-chevron svg {
        width: 11px;
        height: 19px;
      }

      .uk-icon-chevron path {
        fill: black;
      }
    }
  }

  .uk-list-socials {
    margin-top: 30px;

    li {
      display: flex;
      span {
        margin-left: 20px;
        display: inline-block;
      }
    }
  }

  .uk-list-transactions-title {
    display: flex;
    align-items: center;
    margin-top: 72px;

    li:nth-child(n + 2) {
      margin-top: 0;
      margin-left: 16px;
    }

    @media (min-width: $breakpoint-medium) {
      li:nth-child(n + 2) {
        margin-left: 46px;
      }
    }

    .uk-active {
      position: relative;
      font-weight: 600;

      &::after {
        position: absolute;
        content: "";
        width: 75px;
        height: 4px;
        background-color: $global-green-color;
        left: 0;
        bottom: -6px;
      }
    }
  }

  .uk-list-transactions {
    margin-top: 45px;

    li:nth-child(n + 2) {
      margin-top: 16px;
    }

    @media (min-width: $breakpoint-medium) {
      li {
        display: flex;
        align-items: center;

        div:nth-child(n + 2) {
          margin-left: 20px;
        }

        &:nth-child(n + 2) {
          margin-top: 42px;
        }
      }
    }
  }

  .uk-list-sort {
    li {
      background-color: $global-muted-background;
      padding: 19px 24px;
      @include interpolate(font-size, $breakpoint-small, 1440px, 16px, 18px);
      @include interpolate(line-height, $breakpoint-small, 1440px, 24px, 28.8px);
      font-weight: 500;
      display: flex;
      align-items: center;
      border-radius: 6.40725px;

      label {
        margin-left: 20px;
      }

      select {
        height: unset !important;
      }

      input[type="radio"] {
        width: 24px;
        height: 24px;
        accent-color: green;
      }
    }
  }

  .uk-list-social-login {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 260px;
    margin-left: auto !important;
    margin-right: auto !important;

    li {
      margin-top: 0 !important;
      width: 68px;
      height: 68px;
      border-radius: 6px;
      border: 2.4px solid rgba(42, 179, 101, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .uk-list-payment-options {
    margin-top: 3em;
    li + li {
      margin-top: 40px;
      padding-top: 30px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .uk-list-chats {
    max-height: calc(100vh - 330px);
    overflow: auto;
    li {
      padding: 10px 0;
      cursor: pointer;

      &:nth-child(n + 2) {
        border-top: 1px solid rgba(#000000, 0.05);
        margin-top: 0;
      }

      img {
        border-radius: 50%;
      }

      @media (min-width: $breakpoint-medium) {
        padding: 20px 0;
      }
    }
  }

  .uk-list-messages {
    @media (min-width: $breakpoint-medium) {
      min-height: 300px;
      max-height: calc(100vh - 340px);
      height: 100%;
    }
    padding: 20px;
    margin-top: 0;
    height: calc(100vh - 295px);
    overflow: auto;
    box-sizing: border-box;
    li {
      box-sizing: border-box;
      max-width: 80%;
      padding: 10px;
      width: fit-content;
      border-radius: 14px;
      @media (min-width: $breakpoint-medium) {
        max-width: 60%;
      }

      &.sender {
        background-color: $global-green-color;
        color: $global-inverse-color;
        margin-left: auto;
      }

      &.recipient {
        background-color: $global-inverse-color;
        color: $global-primary-background;
        box-shadow: 0px 5px 32px 0px rgba(0, 0, 0, 0.1);
      }

      &.generated {
        background-color: $global-inverse-color;
        color: $global-primary-background;
        text-align: center;
        max-width: 100%;
        width: 100%;
        font-style: italic;
        font-weight: 300;
        box-shadow: 0px 5px 32px 0px rgba(0, 0, 0, 0.1);
      }

      &.otp {
        max-width: 250px;
        text-align: left;

        .otp-copy {
          font-style: normal;

          .uk-icon {
            width: 20px;
            cursor: pointer;

            &.uk-active svg path {
              fill: $global-green-color;
            }
          }
        }
      }
    }
    & > li:nth-child(n + 2) {
      margin-top: 1em;
    }
  }
}
