//
// Component: Text
//
// ========================================================================

@mixin hook-text-misc() {
  // Weights

  .uk-text-fw-400 {
    font-weight: 400;
  }

  .uk-text-fw-500 {
    font-weight: 500;
  }

  .uk-text-fw-600 {
    font-weight: 600;
  }

  // Sizes & line Height

  .uk-text-1,
  h1,
  .uk-h1 {
    @include interpolate(font-size, $breakpoint-small, 1440px, 40px, 62px);
    @include interpolate(line-height, $breakpoint-small, 1440px, 45px, 85px);
  }
  .uk-text-2,
  h2,
  .uk-h2 {
    @include interpolate(font-size, $breakpoint-small, 1440px, 26px, 50px);
    @include interpolate(line-height, $breakpoint-small, 1440px, 39px, 60px);
  }
  .uk-text-3,
  h3,
  .uk-h3 {
    @include interpolate(font-size, $breakpoint-small, 1440px, 23px, 40px);
    @include interpolate(line-height, $breakpoint-small, 1440px, 32.2px, 52px);
  }
  .uk-text-3-small {
    @include interpolate(font-size, $breakpoint-small, 1440px, 23px, 35px);
    @include interpolate(line-height, $breakpoint-small, 1440px, 32.2px, 48px);
  }
  .uk-text-4,
  h4,
  .uk-h4 {
    @include interpolate(font-size, $breakpoint-small, 1440px, 20px, 32px);
    @include interpolate(line-height, $breakpoint-small, 1440px, 30px, 41.6px);
  }
  .uk-text-5,
  h5,
  .uk-h5 {
    @include interpolate(font-size, $breakpoint-small, 1440px, 18px, 28px);
    @include interpolate(line-height, $breakpoint-small, 1440px, 27px, 37.5px);
  }
  .uk-text-5-small {
    @include interpolate(font-size, $breakpoint-small, 1440px, 18px, 26px);
    @include interpolate(line-height, $breakpoint-small, 1440px, 27px, 34px);
  }
  .uk-text-5-xsmall {
    @include interpolate(font-size, $breakpoint-small, 1440px, 18px, 24px);
    @include interpolate(line-height, $breakpoint-small, 1440px, 27px, 41px);
  }
  .uk-text-6-big {
    @include interpolate(font-size, $breakpoint-small, 1440px, 18px, 22px);
    @include interpolate(line-height, $breakpoint-small, 1440px, 30px, 31px);
  }
  .uk-text-6-xbig {
    @include interpolate(font-size, $breakpoint-small, 1440px, 18px, 20px);
    @include interpolate(line-height, $breakpoint-small, 1440px, 30px, 30px);
  }
  .uk-text-6,
  h6,
  .uk-h6 {
    @include interpolate(font-size, $breakpoint-small, 1440px, 16px, 18px);
    @include interpolate(line-height, $breakpoint-small, 1440px, 24px, 28.8px);
  }
  .uk-text-7 {
    @include interpolate(font-size, $breakpoint-small, 1440px, 15px, 16px);
    @include interpolate(line-height, $breakpoint-small, 1440px, 22.5px, 28px);
  }
  .uk-text-8-big {
    font-size: 14px;
    line-height: 18.2px;
  }
  .uk-text-8 {
    @include interpolate(font-size, $breakpoint-small, 1440px, 13px, 13px);
    @include interpolate(line-height, $breakpoint-small, 1440px, 19.5px, 30px);
  }
  .uk-text-9 {
    font-size: 12px;
    line-height: 14.4px;
  }
  .uk-text-10 {
    font-size: 11px;
    line-height: 18px;
  }

  // Colours

  @each $name, $colour in $template_colours {
    .uk-color-#{$name} {
      color: $colour !important;

      svg path {
        fill: $colour;
      }
    }
  }
}
