// Variables
// ========================================================================

$modal-dialog-width: 700px !default;
/* ========================================================================
   Component: Modal
 ========================================================================== */

// Hooks
// ========================================================================

// @mixin hook-modal(){}
// @mixin hook-modal-dialog(){}
// @mixin hook-modal-full(){}
// @mixin hook-modal-header(){}
// @mixin hook-modal-body(){}
// @mixin hook-modal-footer(){}
// @mixin hook-modal-title(){}
// @mixin hook-modal-close(){}
// @mixin hook-modal-close-hover(){}
// @mixin hook-modal-close-default(){}
// @mixin hook-modal-close-default-hover(){}
// @mixin hook-modal-close-outside(){}
// @mixin hook-modal-close-outside-hover(){}
// @mixin hook-modal-close-full(){}
// @mixin hook-modal-close-full-hover(){}
@mixin hook-modal-misc() {
  .uk-modal-booking-hold {
    .uk-modal-dialog {
      max-width: 421px !important;
      text-align: center;
      box-shadow: 0px 4.98225px 31.1391px rgba(0, 0, 0, 0.25);
      border-radius: 6.22781px;

      .uk-text-6-big {
        margin-top: 32px;
      }

      .uk-text-6 {
        margin-top: 18px;
      }

      .uk-text-9 {
        margin-top: 57px;
      }

      .uk-button {
        margin-top: 10px;
        padding: 12px 56px;
        font-size: 18px;
      }
    }
  }

  .uk-modal-booking-rate {
    .uk-modal-dialog {
      max-width: 440px !important;
      padding: 22px 23px 25px 23px;

      .uk-stars {
        margin-top: 30px;

        > *:nth-child(n + 2) {
          margin-left: 14px;
        }
      }

      .uk-textarea {
        margin-top: 21px;
        padding: 22px;
      }

      .uk-button {
        padding: 13.0329px 28.1275px;
      }
    }
  }
}
