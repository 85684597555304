// @mixin hook-panel-scrollable(){}
// @mixin hook-box-shadow-bottom(){}
// @mixin hook-dropcap(){}
@mixin hook-logo() {
  a {
    color: $global-primary-background;
    font-size: 18px;

    img {
      width: 100px;
    }
  }
}
// @mixin hook-logo-hover(){}
@mixin hook-utility-misc() {
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  img.uk-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    left: 0;
    top: 0;
  }

  .uk-field {
    width: 100%;
    position: relative;
    label {
      pointer-events: none;
      cursor: text;
      line-height: 25px;
      padding: 0 4px px;
      position: absolute;
      transform: translate(21px, 16px);
      transition: 0.3s ease;
    }

    .uk-input-container.uk-flex input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    input {
      width: 250px;
      height: 60px;
      display: inline-block;
      vertical-align: middle;
      background: #fff;
      border: 1px solid #b8b8b8;
      border-radius: 3px;
      color: #003a60;
      padding: 0 16px;
      transition: 0.3s ease;
      box-sizing: border-box;

      & + button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    button {
      height: 60px;
    }

    &.uk-active {
      label {
        color: #34a853;
        font-size: 13px;
        line-height: 23px;
        transform: translate(18px, 2px) !important;
      }

      input {
        border-color: #34a853;
        border-width: 2px;
        padding-top: 12px;
      }
    }
  }

  .uk-share {
    width: 100%;
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 4px;
    box-sizing: border-box;
    height: 65px;

    @media (min-width: $breakpoint-medium) {
      max-width: 552px;
    }

    span {
      display: inline-block;
      max-width: 448px;
      color: rgba(#000000, 0.5);
      overflow: hidden;
      width: 100%;
    }

    &.square {
      padding: 14px;
      width: auto;
    }
  }

  .uk-pill {
    padding: 9px;
    border: 1.26791px solid rgba(#000000, 0.25);
    border-radius: 5px;
    width: fit-content;
    font-weight: 600;
  }

  .uk-avatar {
    background-color: #d9d9d9;
    border-radius: 50%;
    width: 90px;
    height: 90px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .uk-upload-aadhaar {
    border: 3.02349px dashed #2ab365;
    border-radius: 7.55873px;
    position: relative;

    svg path {
      fill: white;
    }
  }

  .uk-cursor-pointer {
    cursor: pointer;
  }

  .dropzone {
    border: 1.16279px dashed #2ab365;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }

  .dragging {
    background-color: #f0f0f0;
  }

  ul {
    margin-top: 10px;
    padding-left: 20px;
  }

  li {
    list-style-type: none;
  }

  .uk-overflow-visible {
    overflow: visible !important;
  }

  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrDay > span {
    color: $global-green-color !important;
  }

  .rdrDayNumber span:after {
    background: $global-green-color !important;
  }

  .rdrDateRangePickerWrapper {
    width: fit-content;
    margin: auto;
    display: flex;
  }

  @media (max-width: $breakpoint-medium) {
    .rdrDefinedRangesWrapper {
      display: none;
    }
  }

  // React Calendar

  .react-calendar {
    margin: auto;

    .marked {
      background-color: $global-green-color;
      color: $global-inverse-color;

      &:hover {
        background-color: darken($global-green-color, 5);
      }
    }
  }
}
