//
// Component: Width
//
// ========================================================================

$width-xsmall-width: pxEm(70) !default;
$width-small-width: pxEm(150) !default;
$width-medium-width: pxEm(300) !default;
$width-large-width: 464px !default;
$width-xlarge-width: pxEm(600) !default;
$width-2xlarge-width: pxEm(750) !default;

// Variables
// ========================================================================

// Miscellaneous
// ========================================================================

@mixin hook-width-misc() {
  .uk-width-side-banner {
    max-width: 625px;
  }

  .uk-width-coin {
    max-width: 603px;
    margin: auto;
    display: block;
  }

  .uk-width-coin-full {
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
  }

  .uk-width-complaint {
    max-width: 464px;
    padding: 0 2em;
  }

  .uk-width-review {
    max-width: 606px;
    padding: 0 2em;
    margin: auto;
  }

  .uk-width-fit {
    width: fit-content;
  }

  .uk-width-payments {
    max-width: 694px;
    margin: auto;
  }
}
