// Variables
// ========================================================================

$navbar-background: #fff !default;
$navbar-nav-item-height: 65px !default;

// Component
// ========================================================================

// @mixin hook-navbar(){}

// Container
// ========================================================================

@mixin hook-navbar-container() {
	padding: 0.4rem 10rem !important;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.15);

	@media (max-width: $breakpoint-medium) {
		padding: 0.4rem 1rem !important;
		height: $navbar-nav-item-height;
	}
}

@mixin hook-navbar-misc {
	.uk-navbar-left a {
		color: $global-green-color;
	}

	.uk-navbar-left img {
		width: 60px;
	}

	.uk-navbar-nav > li:nth-child(n + 2) {
		margin-left: 50px;
	}

	.uk-navbar-nav .uk-highlighted {
		background-color: rgba(0, 0, 0, 0.1);
		width: 70px !important;
		height: 70px;
		min-height: 70px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.uk-navbar-nav > li > a,
	.uk-navbar-item,
	.uk-navbar-toggle {
		height: $navbar-nav-item-height;
		padding: 0;
		font-weight: 600;
		transition: 0.3s ease;
	}

	.uk-navbar-nav > li > a.active {
		@media (min-width: $breakpoint-medium) {
			transform: translateY(-10px);
			position: relative;

			&::after {
				position: absolute;
				width: 9px;
				height: 9px;
				content: "";
				background-color: $global-green-color;
				border-radius: 50%;
				left: 50%;
				bottom: 20%;
				transform: translateX(-50%);
			}
		}
	}

	.mobile-menu {
		.uk-navbar-nav {
			margin-top: 50px !important;
			display: block;

			li:nth-child(n + 2) {
				margin-top: 20px;
				margin-left: 0;
			}

			li a {
				justify-content: flex-start;
				height: unset;
				min-height: unset;
			}

			li span:not(.uk-icon) {
				margin-left: 20px;
			}
		}
	}
}

// Nav
// ========================================================================

// @mixin hook-navbar-nav-item-hover(){}

// @mixin hook-navbar-nav-item-onclick(){}

// @mixin hook-navbar-nav-item-active(){}

// Item
// ========================================================================

// @mixin hook-navbar-item(){}
