$accordion-icon-margin-left: 0 !default;
$accordion-icon-color: $global-color !default;
$internal-accordion-open-image: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22#000%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E' !default;
$internal-accordion-close-image: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22#000%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22#000%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E' !default;
$list-divider-border: rgba(0, 0, 0, 0.25);
$accordion-item-margin-top: 0 !default;
// $accordion-content-margin-top: 1em !default;
$accordion-title-color: $global-primary-color;

@mixin hook-accordion-title() {
	overflow: hidden;
	font-weight: 500;

	&::before {
		content: '';
		width: ($accordion-title-line-height * 1em);
		height: ($accordion-title-line-height * 1em);
		margin-left: $accordion-icon-margin-left;
		float: right;
		@include svg-fill(
			$internal-accordion-close-image,
			'#000',
			$accordion-icon-color
		);
		background-repeat: no-repeat;
		background-position: 50% 50%;
	}

	.uk-open > &::before {
		@include svg-fill(
			$internal-accordion-open-image,
			'#000',
			$accordion-icon-color
		);
	}
}

@mixin hook-accordion-content() {
	@include interpolate(font-size, $breakpoint-small, 1440px, 16px, 20px);
	@include interpolate(line-height, $breakpoint-small, 1440px, 28px, 30px);
}

@mixin hook-accordion-misc() {
	.uk-accordion-divider {
		> li {
			padding: 1em;
			margin: 0;
			border-bottom: 1px solid lightgray;

			&:nth-child(1) {
				border-top: 1px solid lightgray;
			}
		}
	}
}
