//
// Component: Margin
//
// ========================================================================

// Variables
// ========================================================================

$global-remove-margin: 0em;
$global-margin: 1em;
$global-xxsmall-margin: 0.25em;
$global-xsmall-margin: 0.4em;
$global-small-margin: 0.7em;
$global-medium-margin: 2em;
$global-large-margin: 3em;
$global-xlarge-margin: 8em;
$global-xxlarge-margin: 16em;

$uk-margin-positions: ("", "-top", "-bottom", "-left", "-right");

@mixin uk-add-margins($size, $margin) {
  @each $position in $uk-margin-positions {
    .uk-margin-#{$size}#{$position} {
      margin#{$position}: $margin !important;
    }
  }
}

@mixin uk-add-responsive-margins($size, $margin, $append: "") {
  @each $position in $uk-margin-positions {
    .uk-margin-#{$size}#{$position}\@#{$append} {
      margin#{$position}: $margin !important;
    }
  }
}

// Miscellaneous
// ========================================================================

@mixin hook-margin-misc() {
  @include uk-add-margins("remove", $global-remove-margin);
  @include uk-add-margins("xxsmall", $global-xxsmall-margin);
  @include uk-add-margins("xsmall", $global-xsmall-margin);
  // @include uk-add-margins("small", $global-small-margin);
  @include uk-add-margins("medium", $global-medium-margin);
  // @include uk-add-margins("large", $global-large-margin);
  // @include uk-add-margins("xlarge", $global-xlarge-margin);
  // @include uk-add-margins("xxlarge", $global-xxlarge-margin);

  // Responsive margin class

  @media (min-width: $breakpoint-small) {
    // @include uk-add-responsive-margins("remove", $global-remove-margin, "s");
    // @include uk-add-responsive-margins("xxsmall", $global-xxsmall-margin, "s");
    // @include uk-add-responsive-margins("small", $global-small-margin, "s");
    // @include uk-add-responsive-margins('medium', $global-medium-margin, 's');
    // @include uk-add-responsive-margins("large", $global-large-margin, "s");
    // @include uk-add-responsive-margins("xlarge", $global-xlarge-margin, "s");
    // @include uk-add-responsive-margins("xxlarge", $global-xxlarge-margin, "s");
  }

  @media (min-width: $breakpoint-medium) {
    // @include uk-add-responsive-margins("remove", $global-remove-margin, "m");
    // @include uk-add-responsive-margins("xxsmall", $global-xxsmall-margin, "m");
    @include uk-add-responsive-margins("xsmall", $global-xsmall-margin, "m");
    @include uk-add-responsive-margins("small", $global-small-margin, "m");
    @include uk-add-responsive-margins("medium", $global-medium-margin, "m");
    @include uk-add-responsive-margins("large", $global-large-margin, "m");
    // @include uk-add-responsive-margins("xlarge", $global-xlarge-margin, "m");
    // @include uk-add-responsive-margins("xxlarge", $global-xxlarge-margin, "m");

    .uk-margin-left\@m {
      margin-left: $global-margin;
    }
  }

  .uk-margin-booking-item {
    margin-top: 16px;
  }
}
