.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 6% 10%;
    padding: 20px;
    background-color: cornflowerblue;
    align-self: center;
    max-width: 100%;
    border: 2px solid black;
    border-radius: 10px;
}

h2 {
    font-family: sans-serif;
    padding: 20px 0;
    color: white;
}

.rdrDefinedRangesWrapper {
    display: none;
}

@media (max-width: 768px) {
    .container {
        padding: 5px;
        padding-bottom: 40px;
        justify-content: center;
        align-items: center;
        display: flex;
        margin-top: 20%;
    }

    h2 {
        font-size: 18px;
    }

    .rdrDateRangePickerWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .rdrCalendarWrapper {
        margin-top: 5px;
    }
}

@media (max-width: 510px) {
    .rdrCalendarWrapper {
        align-items: center;
        width: auto;
        margin-top: 10px;
    }

    .rdrDateDisplayWrapper {
        width: 100%;
    }

    .rdrMonthAndYearWrapper {
        justify-content: space-evenly;
        margin: -10px;
    }

    .rdrMonthsVertical {
        flex-direction: column;
        width: auto;
        align-items: center;
    }

    .rdrMonth {
        padding: 0px 0px 0px 0px;
        width: auto;
    }

    .rdrDays {
        width: 180px;
        align-content: center;
    }
}
